import { render, staticRenderFns } from "./ConfirmBox.vue?vue&type=template&id=08df741e&scoped=true"
import script from "./ConfirmBox.vue?vue&type=script&lang=js"
export * from "./ConfirmBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08df741e",
  null
  
)

export default component.exports