<template>
  <div class="py-2">
    <div style="max-width: 100%; min-width: 100%">
      <v-col cols="12" class="msg-block" ref="msgBlock">
        <template v-if="items && items.length > 0">
          <div
            :key="`msg_${msg.id}`"
            v-for="msg in items"
            :class="`chat-msg ${msgType(msg)}`"
          >
            <v-tooltip
              :right="msgType(msg) == 'chat-type-1'"
              :left="msgType(msg) == 'chat-type-2'"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="chat-content" v-bind="attrs" v-on="on">
                  <img
                    v-if="msg.file"
                    :src="urlFile(msg)"
                    style="max-width: 100%"
                    @click="showImages(urlFile(msg))"
                  />
                  <span v-if="![99].includes(msg.id_crm_action)">
                    {{ $t(`labels.lead_action_${msg.id_crm_action}`) }}
                  </span>
                  <div v-if="[1, 6, 8].includes(msg.id_crm_action)">
                    <div
                      v-for="ct in parseContent({
                        content: msg.content,
                        type: msg.id_crm_action,
                      })"
                      :key="`k_${ct.key}`"
                    >
                      <div v-if="ct.key == 'goods'">
                        <span>{{ $t(`labels.${ct.key}`) }}:</span>
                        <span
                          v-for="(item, index) in ct.value"
                          :key="`i_${index}_${item.barcode}`"
                        >
                          {{ item.barcode }} x {{ item.quantity }}
                          <span v-if="index < ct.value.length - 1">; </span>
                        </span>
                      </div>
                      <div v-else>
                        {{ $t(`labels.${ct.key}`) }}:
                        {{ ct.value }}
                      </div>
                    </div>
                  </div>
                  <div v-else>{{ msg.content }}</div>
                </div>
              </template>
              <span class="fs-12">
                {{ msg.identity_name }} <br />
                {{ formatDateTime(msg.created_at) }}
              </span>
            </v-tooltip>
          </div>
        </template>
      </v-col>
      <div v-if="checkRoleInput">
        <v-text-field
          v-model="content"
          label="Nhập nội dung..."
          class="c-input-small"
          dense
          outlined
          clearable
          hide-details
          single-line
          @keyup.enter="sendNewMessage"
        >
          <!-- <template v-slot:append>
                    <img
                      class="cursor-pointer"
                      width="24"
                      height="24"
                      src="@/assets/internal_requests/add-image.png"
                      @click="$refs.inputUploadFile.click()"
                    />
                  </template> -->
        </v-text-field>
        <input
          type="file"
          ref="inputUploadFile"
          accept="image/*"
          @change="onInputFileChange"
          class="d-none"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { api as viewerApi } from "v-viewer";
import { compress } from "image-conversion";
import moment from "moment";
import { debounce } from "lodash/function";

export default {
  name: "LeadDetailNote",
  props: {
    lead: {
      type: Object,
      default: () => {},
    },
    actionFrom: {
      type: String,
      default: () => "telesale",
    },
    isShowInput: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    items: [],
    content: null,
    file: null,
    isLoading: false,
    viewerOptions: {
      movable: false,
      button: true,
      navbar: true,
      title: false,
      toolbar: true,
      tooltip: false,
      zoomable: false,
      rotatable: false,
      scalable: false,
      transition: true,
      fullscreen: false,
      keyboard: true,
    },
  }),
  computed: {
    itemMessages() {
      return this.items.filter((i) => !!i.content);
    },
    urlFile() {
      return (msg) => process.env.VUE_APP_FILE_CDN_URL + "/" + msg.file;
    },
    imageUrls() {
      const urls = [];
      this.items.forEach((i) => {
        if (i.file) {
          urls.push(this.urlFile(i));
        }
      });
      return urls;
    },
    checkRoleInput() {
      return this.isShowInput;
    },
    msgType() {
      if (!window.me || !window.me.identity_id) {
        return "chat-type-1";
      }
      const identityId = window.me.identity_id;
      return (msg) =>
        identityId === msg.id_identity ? "chat-type-2" : "chat-type-1";
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList: debounce(function () {
      httpClient
        .post("/crm-lead-get-log-action", { id: this.lead.id })
        .then(({ data }) => {
          this.items = [...data];
          this.scrollToBottom();
        });
    }, 500),
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.msgBlock.scrollTop = this.$refs.msgBlock.scrollHeight;
      });
    },
    onInputFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.uploadFile(files[0]);
    },

    async uploadFile(file) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const resBlob = await compress(file, {
        quality: 0.85,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${moment().unix()}_${this.generateRandomString(10)}.jpg`,
        { type: "image/jpeg" }
      );
      let fd = new FormData();
      fd.append("file", newFile);

      httpClient
        .post(`/upload-image`, fd)
        .then((res) => {
          this.sendNewFile({ id: this.lead.id, file: res.data });
          this.$refs.inputUploadFile.value = null;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log("uploadFile err", err);
          this.$refs.inputUploadFile.value = null;
          this.isLoading = false;
        });
    },
    async sendNewFile(msg) {
      this.$emit("sendNewMessage", { ...msg });
    },
    async sendNewMessage() {
      // this.$emit("sendNewMessage", { ...this.item, content: this.content });
      const { me } = window;
      httpClient
        .post(`/crm-lead-telesale-note`, {
          id: this.lead.id,
          content: this.content,
          action_from: this.actionFrom,
        })
        .then(({ data }) => {
          this.isLoading = false;
          this.items.push({
            id: data.id,
            content: this.content,
            created_at: moment().toISOString(),
            updated_at: moment().toISOString(),
            id_crm_action: 99,
            id_crm_lead: this.lead.id,
            id_identity: me.identity_id,
            identity_name: me.display_name,
            status: 1,
          });
          this.content = null;
          this.scrollToBottom();
        })
        .catch((err) => {
          this.isLoading = false;
          this.content = null;
          console.log("sendNewMessage err", err);
        });
    },
    showImages(url) {
      const index = this.imageUrls.findIndex((u) => u === url);
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images: this.imageUrls,
      });
    },
    parseContent({ content, type }) {
      const data = content ? JSON.parse(content) : {};
      if (data.goods_txt) {
        data.goods = JSON.parse(data.goods_txt);
      }

      const allowByType = {
        1: ["phone", "name", "cod", "goods"],
        6: ["action", "start", "end", "duration", "reprocess_at"],
        8: ["action", "start", "end", "duration", "reprocess_at"],
      };

      const allowKeys = allowByType[type];
      const keys = Object.keys(data);

      const newContent = [];
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (allowKeys.includes(key)) {
          let value = data[key];
          switch (key) {
            case "action":
              value = this.$t(`labels.${value}`);
              break;
            case "start":
            case "end":
            case "reprocess_at":
              value = this.formatDateTime(value, "HH:mm:ss DD/MM/YYYY");
              break;
            case "duration":
              value = this.secondTimeHuman(value);
              break;
            default:
              value = data[key];
              break;
          }
          newContent.push({
            key,
            value,
          });
        }
      }

      return newContent;
    },
  },
};
</script>

<style scoped lang="scss">
.msg-block {
  width: 100%;
  min-width: 300px;
  padding-top: 3px;
  padding-bottom: 3px;
  height: calc(100vh - 500px);
  overflow-y: auto;
  border: solid 1px black;
  border-radius: 3px;
  background-color: white;
}

.chat-msg {
  margin-bottom: 10px;
}

.chat-name {
  background-color: transparent;
  font-style: italic;
  font-size: 2.5mm;
  color: grey;
}

.chat-type-1 {
  .chat-content {
    padding: 5px;
    width: auto;
    max-width: 65%;
    background-color: lightgrey;
    border: 1px solid transparent;
    border-radius: 10px;
    text-align: left;
    text-wrap: wrap;
    font-size: 13px;
  }
}

.chat-type-2 {
  text-align: right;

  .chat-content {
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: dodgerblue !important;
    color: white !important;
    width: 65%;
    margin-left: 35%;
    text-wrap: wrap;
    font-size: 13px;
  }
}
</style>
