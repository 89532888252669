<template>
  <div>
    <v-card class="card-custom-shadow">
      <v-card-text>
        <div>
          <v-simple-table class="table-padding-2">
            <template v-slot:default>
              <tbody>
                <tr class="text-center">
                  <td>
                    <v-text-field
                      v-model="leadInfo.name"
                      class="c-input-small"
                      :class="{
                        'input-error':
                          !leadInfo.name || leadInfo.name.length > 250,
                      }"
                      :label="$t('labels.receiver')"
                      outlined
                      dense
                      hide-details
                      @change="onInputChange"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="leadInfo.phone"
                      class="c-input-small"
                      readonly
                      :class="{
                        'input-error':
                          !leadInfo.phone ||
                          !isPhoneValidByCountry({
                            phone: leadInfo.phone,
                            id_country: customer.id_country,
                          }),
                      }"
                      :label="$t('labels.phone_number')"
                      outlined
                      dense
                      hide-details
                      @change="onInputChange"
                    ></v-text-field>
                  </td>
                  <td>
                    <SelectCity
                      class="c-input-xs"
                      :class="{
                        'input-error': !leadInfo.id_city,
                      }"
                      :id-item="leadInfo.id"
                      :id-city="leadInfo.id_city"
                      :label="$t('labels.city')"
                      name="id_city"
                      @onChange="onItemChange"
                    />
                  </td>
                  <td>
                    <SelectCounty
                      class="c-input-xs"
                      :class="{
                        'input-error': !leadInfo.id_county,
                      }"
                      :id-item="leadInfo.id"
                      :id-city="leadInfo.id_city"
                      :id-county="leadInfo.id_county"
                      :label="$t('labels.district')"
                      name="id_county"
                      single-line
                      @onChange="onItemChange"
                    />
                  </td>
                  <td>
                    <SelectWard
                      class="c-input-xs"
                      :class="{
                        'input-error': !leadInfo.id_ward,
                      }"
                      :id-item="leadInfo.id"
                      :id-city="leadInfo.id_city"
                      :id-county="leadInfo.id_county"
                      :id-ward="leadInfo.id_ward"
                      :label="$t('labels.ward')"
                      name="id_ward"
                      @onChange="onItemChange"
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="leadInfo.address"
                      :class="{
                        'input-error':
                          !leadInfo.address || leadInfo.address.length > 250,
                      }"
                      class="c-input-small"
                      :label="$t('labels.address')"
                      outlined
                      dense
                      hide-details
                      @change="onInputChange"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="text-center">
                  <td colspan="6">
                    <v-textarea
                      v-model="leadInfo.note"
                      class="c-input-small"
                      outlined
                      dense
                      hide-details
                      rows="2"
                      :label="$t('labels.note')"
                      @change="onInputChange"
                    ></v-textarea>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "LeadDetailInfo",
  components: {
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
  },
  props: {
    lead: {
      type: Object,
      default: () => {},
    },
    actionFrom: {
      type: String,
      default: () => "telesale",
    },
  },
  data: () => ({
    leadInfo: {},
    customer: {},
  }),
  computed: {},
  created() {
    const { customer } = window.me;
    this.customer = { ...customer };
    this.leadInfo = { ...this.lead };
  },
  methods: {
    onItemChange(val) {
      this.leadInfo = { ...this.leadInfo, [val.name]: val.value };
    },
    onInputChange() {
      httpClient.post("/crm-lead-telesale-update-info", {
        ...this.leadInfo,
        action_from: this.actionFrom,
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
