var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"card-custom-shadow"},[_c('v-card-text',[_c('div',[_c('v-simple-table',{staticClass:"table-padding-2",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{staticClass:"text-center"},[_c('td',[_c('v-text-field',{staticClass:"c-input-small",class:{
                      'input-error':
                        !_vm.leadInfo.name || _vm.leadInfo.name.length > 250,
                    },attrs:{"label":_vm.$t('labels.receiver'),"outlined":"","dense":"","hide-details":""},on:{"change":_vm.onInputChange},model:{value:(_vm.leadInfo.name),callback:function ($$v) {_vm.$set(_vm.leadInfo, "name", $$v)},expression:"leadInfo.name"}})],1),_c('td',[_c('v-text-field',{staticClass:"c-input-small",class:{
                      'input-error':
                        !_vm.leadInfo.phone ||
                        !_vm.isPhoneValidByCountry({
                          phone: _vm.leadInfo.phone,
                          id_country: _vm.customer.id_country,
                        }),
                    },attrs:{"readonly":"","label":_vm.$t('labels.phone_number'),"outlined":"","dense":"","hide-details":""},on:{"change":_vm.onInputChange},model:{value:(_vm.leadInfo.phone),callback:function ($$v) {_vm.$set(_vm.leadInfo, "phone", $$v)},expression:"leadInfo.phone"}})],1),_c('td',[_c('SelectCity',{staticClass:"c-input-xs",class:{
                      'input-error': !_vm.leadInfo.id_city,
                    },attrs:{"id-item":_vm.leadInfo.id,"id-city":_vm.leadInfo.id_city,"label":_vm.$t('labels.city'),"name":"id_city"},on:{"onChange":_vm.onItemChange}})],1),_c('td',[_c('SelectCounty',{staticClass:"c-input-xs",class:{
                      'input-error': !_vm.leadInfo.id_county,
                    },attrs:{"id-item":_vm.leadInfo.id,"id-city":_vm.leadInfo.id_city,"id-county":_vm.leadInfo.id_county,"label":_vm.$t('labels.district'),"name":"id_county","single-line":""},on:{"onChange":_vm.onItemChange}})],1),_c('td',[_c('SelectWard',{staticClass:"c-input-xs",class:{
                      'input-error': !_vm.leadInfo.id_ward,
                    },attrs:{"id-item":_vm.leadInfo.id,"id-city":_vm.leadInfo.id_city,"id-county":_vm.leadInfo.id_county,"id-ward":_vm.leadInfo.id_ward,"label":_vm.$t('labels.ward'),"name":"id_ward"},on:{"onChange":_vm.onItemChange}})],1),_c('td',[_c('v-text-field',{staticClass:"c-input-small",class:{
                      'input-error':
                        !_vm.leadInfo.address || _vm.leadInfo.address.length > 250,
                    },attrs:{"label":_vm.$t('labels.address'),"outlined":"","dense":"","hide-details":""},on:{"change":_vm.onInputChange},model:{value:(_vm.leadInfo.address),callback:function ($$v) {_vm.$set(_vm.leadInfo, "address", $$v)},expression:"leadInfo.address"}})],1)]),_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"colspan":"6"}},[_c('v-textarea',{staticClass:"c-input-small",attrs:{"outlined":"","dense":"","hide-details":"","rows":"2","label":_vm.$t('labels.note')},on:{"change":_vm.onInputChange},model:{value:(_vm.leadInfo.note),callback:function ($$v) {_vm.$set(_vm.leadInfo, "note", $$v)},expression:"leadInfo.note"}})],1)])])]},proxy:true}])})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }