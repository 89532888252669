<template>
  <div>
    <v-card class="card-custom-shadow">
      <v-card-text>
        <div class="mb-5 fs-12">
          <v-row no-gutters class="fs-12">
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="4" class="black--text text-right">
                  {{ $t("labels.created") }}: &nbsp;
                </v-col>
                <v-col cols="8">
                  {{ formatDateTime(lead.created_at) }}
                </v-col>
                <v-col cols="4" class="black--text text-right">
                  {{ $t("labels.employee_0") }}: &nbsp;
                </v-col>
                <v-col cols="8">
                  {{ lead.identity_create_name }}
                </v-col>
                <v-col cols="4" class="black--text text-right">
                  {{ $t("labels.channel") }}: &nbsp;
                </v-col>
                <v-col cols="8">
                  {{ $t(`labels.source_type_${lead.source_type_code}`) }}
                </v-col>
                <v-col cols="4" class="black--text text-right">
                  {{ $t("labels.shop") }}: &nbsp;
                </v-col>
                <v-col cols="8">
                  {{ lead.source_name }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="4" class="black--text text-right">
                  {{ $t("labels.classify") }}: &nbsp;
                </v-col>
                <v-col cols="8">
                  <span class="font-weight-medium purple--text">
                    {{
                      !lead.process_count
                        ? $t("labels.lead_process_count_0")
                        : $t("labels.lead_process_count_number", {
                            number: lead.process_count,
                          })
                    }}
                  </span>
                </v-col>
                <v-col cols="4" class="black--text text-right">
                  {{ $t("labels.process_time") }}: &nbsp;
                </v-col>
                <v-col cols="8">
                  <span
                    class="font-weight-medium error--text"
                    :key="updatedKey"
                  >
                    {{ lead.start_time ? subTimeHuman(lead.start_time) : "" }}
                  </span>
                </v-col>
                <v-col cols="4" class="black--text text-right">
                  {{ $t("labels.lead_same_phone") }}: &nbsp;
                </v-col>
                <v-col cols="8">
                  {{ lead.lead_same_contact }}
                </v-col>
                <v-col cols="4" class="black--text text-right">
                  {{ $t("labels.order_success_0") }}: &nbsp;
                </v-col>
                <v-col cols="8">
                  <span class="success--text font-weight-medium">
                    {{ lead.order_success }}
                  </span>
                  /
                  <span class="primary--text font-weight-medium">
                    {{ lead.order_same_contact }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-row dense>
          <v-col cols="6">
            <v-btn block color="primary" disabled @click="onAction('call')">
              {{ $t("labels.call") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="warning" @click="onAction('unsuccessful')">
              {{ $t("labels.unsuccessful") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="error" @click="onAction('reject')">
              {{ $t("labels.reject") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="primary" @click="onAction('wait_confirm')">
              {{ $t("labels.wait_confirm") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="purple" @click="onAction('wrong_phone')" class="white--text">
              {{ $t("labels.wrong_phone") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="success" @click="onAction('confirm')">
              {{ $t("labels.confirm") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="unsuccessfulDialog" persistent max-width="360px">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("labels.unsuccessful") }}
        </v-card-title>
        <v-card-text>
          {{ $t("labels.lead_unsuccessful_description") }}
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                class="c-input-small"
                v-model="unsuccessfulSchedule"
                :items="yesNoOptions"
                :label="$t('labels.recall_timer')"
                :placeholder="$t('labels.recall_timer')"
                dense
                outlined
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" v-if="!!unsuccessfulSchedule">
              <date-time-picker
                v-model="reprocessAt"
                :label="$t('labels.time')"
                :placeholder="$t('labels.time')"
                :single-line="false"
                :min-date="minDate"
              ></date-time-picker>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideDialogUnsuccessful">
            {{ $t("labels.cancel") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmUnsuccessful">
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_LANG_OPTIONS } from "@/libs/const";
import moment from "moment/moment";

export default {
  name: "LeadDetailAction",
  components: {},
  props: {
    lead: {
      type: Object,
      default: () => {},
    },
    actionFrom: {
      type: String,
      default: () => "telesale",
    },
  },
  data: () => ({
    isLoading: false,
    unsuccessfulDialog: false,
    unsuccessfulSchedule: 0,
    reprocessAt: "",
    yesNoOptions: [...YES_NO_LANG_OPTIONS],
    updatedKey: "",
  }),
  computed: {
    minDate() {
      return moment().add(5, "minutes").format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.countdownForTime();
  },
  methods: {
    countdownForTime() {
      if (!this.lead.start_time) {
        return false;
      }
      setTimeout(() => {
        this.updatedKey = this.generateRandomString(10);
        this.countdownForTime();
      }, 1000);
    },
    showDialogUnsuccessful() {
      this.unsuccessfulDialog = true;
    },
    hideDialogUnsuccessful() {
      this.unsuccessfulSchedule = 0;
      this.reprocessAt = "";
      this.unsuccessfulDialog = false;
    },
    async confirmUnsuccessful() {
      if (this.unsuccessfulSchedule) {
        if (!this.reprocessAt) {
          this.$vToastify.error(this.$t("messages.process_at_can_not_null"));
          return false;
        }

        const minProcess = moment()
          .add(5, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        if (this.reprocessAt < minProcess) {
          this.$vToastify.error(
            this.$t("messages.process_at_can_not_less_than_min", {
              min: minProcess,
            })
          );
          return false;
        }
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/crm-lead-telesale-process", {
          id: this.lead.id,
          action: "unsuccessful",
          action_from: this.actionFrom,
          reprocess_at: this.reprocessAt,
        });
        this.isLoading = false;
        window.location.reload();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async onAction(action) {
      if (action == "unsuccessful") {
        this.showDialogUnsuccessful();
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/crm-lead-telesale-process", {
          id: this.lead.id,
          action,
          action_from: this.actionFrom,
        });
        this.isLoading = false;
        window.location.reload();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
