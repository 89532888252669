<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center" style="width: 160px">
              <SelectFilter
                class="c-input-xs input-filter"
                :options="typeOptions"
                :label="$t('labels.type')"
                :placeholder="$t('labels.type')"
                name="type"
                sort-name="type"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <template v-if="checkPermission(['crm_lead_create'])">
              <th role="columnheader" style="width: 175px">
                <InputFilterFromTo
                  :label="$t('labels.crm_telesale_process_number')"
                  :placeholder="$t('labels.crm_telesale_process_number')"
                  name="crm_telesale_process_number"
                  sort-name="crm_telesale_process_number"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center">
                <SelectFilter
                  class="c-input-xs input-filter"
                  :options="crmLeadRetryTimeOptions"
                  :label="$t('labels.crm_telesale_retry_time')"
                  :placeholder="$t('labels.crm_telesale_retry_time')"
                  name="crm_telesale_retry_time"
                  sort-name="crm_telesale_retry_time"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="width: 120px">
                <SelectFilter
                  :options="yesNoOptions"
                  :label="$t('labels.crm_is_double_check')"
                  :placeholder="$t('labels.crm_is_double_check')"
                  name="crm_is_double_check"
                  @onFilter="onFilterChange"
                />
              </th>
              <th role="columnheader" style="width: 180px">
                <InputFilterFromTo
                  :label="$t('labels.crm_double_check_process_number')"
                  :placeholder="$t('labels.crm_double_check_process_number')"
                  name="crm_double_check_process_number"
                  sort-name="crm_double_check_process_number"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center">
                <SelectFilter
                  class="c-input-xs input-filter"
                  :options="crmLeadRetryTimeOptions"
                  :label="$t('labels.crm_double_check_retry_time')"
                  :placeholder="$t('labels.crm_double_check_retry_time')"
                  name="crm_double_check_retry_time"
                  sort-name="crm_double_check_retry_time"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
            </template>
            <th class="text-center" style="width: 105px">
              <v-btn small color="primary" @click="addItem">
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id" class="text-center">
            <td>
              <v-autocomplete
                class="c-input-small input-disabled-bold"
                v-model="item.type"
                :items="typeOptions"
                :disabled="!item.editing || !!item.id"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td class="">
              <v-text-field
                v-model="item.name"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <template v-if="checkPermission(['crm_lead_create'])">
              <td>
                <v-text-field
                  v-model="item.crm_telesale_process_number"
                  :disabled="!item.editing"
                  class="c-input-small input-disabled-bold"
                  outlined
                  dense
                  hide-details
                  single-line
                  type="number"
                ></v-text-field>
              </td>
              <td>
                <v-autocomplete
                  class="c-input-small input-disabled-bold"
                  v-model="item.crm_telesale_retry_time"
                  :items="crmLeadRetryTimeOptions"
                  :disabled="!item.editing"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="item.crm_is_double_check"
                    :disabled="!item.editing"
                    class="c-input-small mt-0"
                    dense
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
              <td>
                <v-text-field
                  v-model="item.crm_double_check_process_number"
                  :disabled="!item.editing"
                  class="c-input-small input-disabled-bold"
                  outlined
                  dense
                  hide-details
                  single-line
                  type="number"
                ></v-text-field>
              </td>
              <td>
                <v-autocomplete
                  class="c-input-small input-disabled-bold"
                  v-model="item.crm_double_check_retry_time"
                  :items="crmLeadRetryTimeOptions"
                  :disabled="!item.editing"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
            </template>
            <td class="text-center">
              <v-btn
                v-if="!item.editing"
                class="mr-1"
                x-small
                color="warning"
                @click="toggleEditing(item, index, true)"
              >
                {{ $t("labels.edit") }}
              </v-btn>
              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="saveItem(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  class="mr-1"
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS, LEAD_RETRY_TIME } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "SourceConfig",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
  },
  props: {},
  data: () => ({
    yesNoOptions: [...YES_NO_OPTIONS],
    crmLeadRetryTimeOptions: [...LEAD_RETRY_TIME],
    sourceTypes: [],
    items: [],
    isLoading: false,
    filters: {},
  }),
  computed: {
    typeOptions() {
      return [...this.sourceTypes].map((st) => ({
        text: this.$t(`labels.source_type_${st.code}`),
        value: st.id,
      }));
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
    this.getSourceType();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async getSourceType() {
      const { data } = await httpClient.post("/source-type");
      this.sourceTypes = [...data];
    },
    getList: debounce(function () {
      httpClient.post("/source-list", this.filters).then(({ data }) => {
        this.items = [...data].map((item) => ({
          ...item,
          editing: false,
        }));
        this.isLoading = false;
      });
    }, 500),

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    addItem() {
      const item = {
        editing: true,
        id: null,
        type: null,
        name: null,
        crm_telesale_process_number: 3,
        crm_telesale_retry_time: 1440,
        crm_is_double_check: 0,
        crm_double_check_process_number: 3,
        crm_double_check_retry_time: 1440,
      };
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async saveItem(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/source-save", item);
        this.isLoading = false;
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
