<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ updateItem.tracking_id }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="confirm">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      {{ $t("labels.lead_update_info") }}
    </v-card-text>

    <v-card-text>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            v-model="updateItem.name"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.receiver')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="updateItem.phone"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.phone_number')"
            :class="{
              'input-error':
                !updateItem.phone ||
                !isPhoneValidByCountry({
                  phone: updateItem.phone,
                  id_country: customer.id_country,
                }),
            }"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <SelectCity
            class="c-input-xs"
            :id-item="updateItem.id"
            :id-city="updateItem.id_city"
            :label="$t('labels.city')"
            name="id_city"
            @onChange="onItemChange"
          />
        </v-col>
        <v-col cols="6">
          <SelectCounty
            class="c-input-xs"
            :id-item="updateItem.id"
            :id-city="updateItem.id_city"
            :id-county="updateItem.id_county"
            :label="$t('labels.district')"
            name="id_county"
            @onChange="onItemChange"
          />
        </v-col>
        <v-col col="4">
          <SelectWard
            class="c-input-xs"
            :id-item="updateItem.id"
            :id-city="updateItem.id_city"
            :id-county="updateItem.id_county"
            :id-ward="updateItem.id_ward"
            :label="$t('labels.ward')"
            name="id_ward"
            @onChange="onItemChange"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="updateItem.address"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.address')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="updateItem.note"
            class="c-input-small"
            outlined
            dense
            hide-details
            rows="2"
            :label="$t('labels.note')"
          ></v-textarea>
        </v-col>

        <v-col col="12">
          <input-number
            v-model.number="updateItem.cod"
            type="number"
            outlined
            dense
            hide-details
            :label="$t('labels.cod')"
            :placeholder="$t('labels.cod')"
            class="c-input-small"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="d-flex align-center justify-space-between">
        <span>{{ $t("labels.goods") }}</span>
        <v-btn x-small color="primary" @click="addRow">
          {{ $t("labels.add") }}
        </v-btn>
      </div>
    </v-card-text>
    <v-card-text>
      <v-row dense v-for="(goods, index) in goodsItems" :key="`g_${index}`">
        <v-col cols="10">
          <SelectSuggestGoodsLimit
            :index="index"
            :default-value="goods.barcode"
            @onSelect="selectItem"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="goods.quantity"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.quantity')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "LeadUpdate",
  components: {
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
    SelectSuggestGoodsLimit: () =>
      import("@/components/common/SelectSuggestGoodsLimit"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    updateItem: null,
    goodsItems: [],
  }),
  created() {
    const { customer } = window.me;
    this.customer = { ...customer };
    this.updateItem = { ...this.item };
    if (this.item.goods_txt) {
      const goodsItems = JSON.parse(this.item.goods_txt);
      this.goodsItems = [...goodsItems];
    }
  },
  methods: {
    onItemChange(val) {
      this.updateItem = { ...this.updateItem, [val.name]: val.value };
    },
    cancel() {
      this.$emit("cancel");
    },
    async confirm() {
      if (this.item.phone == this.updateItem.phone) {
        this.$vToastify.error(this.$t('messages.phone_not_update'));
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/crm-lead-invalid-phone-update", {
          id: this.updateItem.id,
          ...this.updateItem,
          goods_items: this.goodsItems,
        });
        this.isLoading = false;
        this.cancel();
        this.$emit("refreshData");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    checkDuplicate(item, index) {
      return [...this.goodsItems].filter((jtem, jdex) => {
        return (
          jtem.barcode.toUpperCase() === item.barcode.toUpperCase() &&
          index !== jdex
        );
      });
    },

    addRow() {
      this.goodsItems.push({ barcode: "", quantity: 1 });
    },
    delRow(index) {
      this.goodsItems = [...this.goodsItems].filter((item, i) => i !== index);
    },

    selectItem({ item, index }) {
      console.log(item);
      const newItem = {
        barcode: item.customer_goods_barcode,
        id_goods: item.id,
        // quantity: item.quantity,
      };

      if (!newItem.barcode) {
        return false;
      }

      const duplicates = this.checkDuplicate(newItem, index);
      if (duplicates.length > 0) {
        const duplicateCodes = [...duplicates].map((i) => i.barcode);
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateCodes.join("; "),
          })
        );
        return false;
      }
      const goodsItems = [...this.goodsItems];
      goodsItems[index] = { ...newItem, quantity: 1 };
      this.goodsItems = [...goodsItems];
    },
  },
};
</script>

<style scoped></style>
