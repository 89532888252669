<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      :is-page-show="false"
      api-url="/crm-lead-no-demand"
      api-download-url="/crm-lead-no-demand-export"
      download-file-name="lead-no-demand.xlsx"
      @showDialogAction="showDialogAction"
      @leadReject="leadReject"
      @leadConfirm="leadConfirm"
    ></custom-table>

    <v-dialog v-model="dialogAction" persistent max-width="640px">
      <LeadLogAction
        v-if="dialogAction"
        :item="updatingItem"
        @cancel="hideDialogAction"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  CUSTOM_VIEW_CRM_LEAD_INVALID_PHONE,
  LEAD_MODELS,
  LEAD_PROCESS_COUNT,
} from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "LeadNoDemand",
  components: {
    LeadLogAction: () => import("@/components/crm/LeadLogAction"),
  },
  data: () => ({
    customView: CUSTOM_VIEW_CRM_LEAD_INVALID_PHONE,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    statusOptions: [],
    dialogChangeModel: false,
    dialogAction: false,
    updatingItem: {},
  }),
  computed: {},
  watch: {},
  created() {
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
        action: "showDialogAction",
        actionText: this.$t("labels.preview"),
        actionPermissions: [],
      },
      {
        type: "select-filter",
        label: this.$t("labels.classify"),
        placeholder: this.$t("labels.classify"),
        name: "process_count",
        hasSort: false,
        sortName: "process_count",
        key: "process_count",
        required: true,
        options: [...LEAD_PROCESS_COUNT],
      },
      {
        type: "select-filter",
        label: this.$t("labels.model"),
        placeholder: this.$t("labels.model"),
        name: "process_model",
        hasSort: false,
        sortName: "process_model",
        key: "process_model",
        required: true,
        options: [...LEAD_MODELS],
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time"),
        placeholder: this.$t("labels.create_time"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      {
        type: "select-source-type",
        label: this.$t("labels.channel"),
        placeholder: this.$t("labels.channel"),
        name: "id_source_type",
        hasSort: false,
        sortName: "id_source_type",
        key: "source_type_code",
        required: true,
        options: [],
      },
      {
        type: "select-other-source",
        label: this.$t("labels.shop"),
        placeholder: this.$t("labels.shop"),
        name: "id_source",
        hasSort: false,
        sortName: "id_source",
        key: "source_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.receiver"),
        placeholder: this.$t("labels.receiver"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.phone_number"),
        placeholder: this.$t("labels.phone_number"),
        name: "phone",
        hasSort: false,
        sortName: "phone",
        key: "phone",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.city"),
        placeholder: this.$t("labels.city"),
        name: "city_name",
        hasSort: false,
        sortName: "city_name",
        key: "city_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.district"),
        placeholder: this.$t("labels.district"),
        name: "county_name",
        hasSort: false,
        sortName: "county_name",
        key: "county_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.ward"),
        placeholder: this.$t("labels.ward"),
        name: "ward_name",
        hasSort: false,
        sortName: "ward_name",
        key: "ward_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.address"),
        placeholder: this.$t("labels.address"),
        name: "address",
        hasSort: false,
        sortName: "address",
        key: "address",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.cod"),
        placeholder: this.$t("labels.cod"),
        name: "cod",
        hasSort: false,
        sortName: "cod",
        key: "cod",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.order_prepay"),
        placeholder: this.$t("labels.order_prepay"),
        name: "prepay",
        hasSort: false,
        sortName: "prepay",
        key: "prepay",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods"),
        placeholder: this.$t("labels.goods"),
        name: "barcode",
        hasSort: false,
        sortName: "barcode",
        key: "item_html",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.note"),
        placeholder: this.$t("labels.note"),
        name: "note",
        hasSort: false,
        sortName: "note",
        key: "note",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {
    // this.getList()
  },
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },

    showDialogAction(item) {
      this.updatingItem = { ...item };
      this.dialogAction = true;
    },

    hideDialogAction() {
      this.updatingItem = {};
      this.dialogAction = false;
    },

    leadConfirm(item) {
      if (
        !confirm(
          this.$t("messages.are_you_sure_process_wait_confirm_lead", {
            action: this.$t("labels.confirm"),
            tracking: item.tracking_id,
          })
        )
      ) {
        return false;
      }

      this.leadAction({ lead: item, action: "confirm" });
    },

    leadReject(item) {
      if (
        !confirm(
          this.$t("messages.are_you_sure_process_wait_confirm_lead", {
            action: this.$t("labels.reject"),
            tracking: item.tracking_id,
          })
        )
      ) {
        return false;
      }
      this.leadAction({ lead: item, action: "reject" });
    },

    async leadAction({ lead, action }) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/crm-lead-wait-confirm-process", {
          id: lead.id,
          action,
        });
        this.isLoading = false;
        this.refreshCustomTable();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
