<template>
  <div>
    <S1Book />
    <!-- <S2Book /> -->
  </div>
</template>

<script>
export default {
  name: "SubsidiaryBook",
  components: {
    S1Book: () => import("@/components/invoice/S1Book"),
    // S2Book: () => import("@/components/invoice/S2Book"),
  },
};
</script>
