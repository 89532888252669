var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideDropdown),expression:"hideDropdown"}],staticClass:"position-relative"},[_c('v-text-field',{staticClass:"c-input-small",attrs:{"label":_vm.$t('labels.barcode_sku_name_description_size'),"disabled":_vm.disabled,"outlined":"","dense":"","hide-details":"","placeholder":_vm.$t('labels.barcode_sku_name_description_size')},on:{"focus":_vm.showDropdown,"keyup":_vm.getSuggestion},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),(_vm.dropdown)?_c('div',{staticClass:"position-absolute result-list"},[(_vm.suggestions.length > 0 || _vm.comboSuggestions.length > 0)?[(_vm.suggestions.length > 0)?_c('div',{staticClass:"mb-10"},[_c('v-simple-table',{staticClass:"table-padding-2-no-top",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',{staticClass:"text-center error--text",staticStyle:{"width":"50%"}},[_vm._v(" "+_vm._s(_vm.$t("labels.product"))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("labels.size")))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("labels.available")))]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.isPos ? _vm.$t("labels.pos") : _vm.$t("labels.warehouse_1"))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("labels.price")))])])]),_c('tbody',_vm._l((_vm.suggestions),function(suggestion){return _c('tr',{key:`g_${suggestion.id}_${suggestion.id_warehouse}_${suggestion.id_pos}`,staticClass:"result-item text-center",class:{
                  'selected-item': _vm.selectedGoodsIds.includes(suggestion.id),
                }},[_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticStyle:{"width":"50px"}},[_c('ImageViewer',{attrs:{"url":suggestion.url_images ||
                          require('@/assets/common/no-image.jpg'),"width":"40px","height":"40px"}})],1),_c('div',[_c('div',[_c('span',{staticClass:"font-weight-medium",staticStyle:{"color":"#b71c1c"}},[_vm._v(" "+_vm._s(suggestion.customer_goods_barcode)+" ")]),_vm._v(" - "+_vm._s(_vm.$t("labels.sku"))+": "+_vm._s(suggestion.sku)+" ")]),_c('div',[_vm._v(" "+_vm._s(suggestion.name)+" - "+_vm._s(suggestion.description)+" ")])])])]),_c('td',[_vm._v(_vm._s(suggestion.size))]),_c('td',[_vm._v(_vm._s(suggestion.available))]),_c('td',[_vm._v(" "+_vm._s(_vm.isPos ? suggestion.pos_code : suggestion.warehouse_code)+" ")]),_c('td',[(
                      !_vm.selectedGoodsIds.includes(
                        `${suggestion.id}_${suggestion.id_warehouse}_${suggestion.id_pos}`
                      )
                    )?[(suggestion.price !== null)?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.selectItem(suggestion, 'price', 'goods')}}},[_vm._v(" "+_vm._s(_vm.formatNumber(suggestion.price))+" ")]):_c('span',{staticClass:"font-italic error--text"},[_vm._v(" "+_vm._s(_vm.$t("labels.not_yet_price"))+" ")])]:[_vm._v(" "+_vm._s(suggestion.price || suggestion.price == 0 ? _vm.formatNumber(suggestion.price) : _vm.$t("labels.not_yet_price"))+" ")]],2)])}),0)]},proxy:true}],null,false,1109148246)}),(_vm.totalPage > 1)?_c('div',{staticClass:"pt-2"},[_c('v-pagination',{staticClass:"mini-pagination",attrs:{"length":_vm.totalPage,"total-visible":6},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1):_vm._e(),(_vm.comboSuggestions.length > 0)?_c('div',[_c('v-simple-table',{staticClass:"table-padding-2-no-top",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',{staticClass:"text-center error--text",staticStyle:{"width":"50%"}},[_vm._v(" "+_vm._s(_vm.$t("labels.combo"))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("labels.available")))]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.isPos ? _vm.$t("labels.pos") : _vm.$t("labels.warehouse_1"))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("labels.price")))])])]),_c('tbody',_vm._l((_vm.comboSuggestions),function(item){return _c('tr',{key:`cb_${item.id}_${item.id_warehouse}_${item.id_pos}`,staticClass:"result-item",class:{
                  'selected-item': _vm.selectedComboIds.includes(item.id),
                }},[_c('td',{staticClass:"text-left"},[_c('div',{staticClass:"pl-1"},[_c('span',{staticClass:"font-weight-medium",staticStyle:{"color":"#b71c1c"}},[_vm._v(" "+_vm._s(item.barcode)+" ")]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(item.name))])]),_vm._l((item.goods),function(g,k){return _c('div',{key:`cg_${k}_${g.id}`,staticClass:"d-flex align-center"},[_c('div',{staticStyle:{"width":"50px"}},[_c('ImageViewer',{attrs:{"url":g.url_images ||
                          require('@/assets/common/no-image.jpg'),"width":"40px","height":"40px"}})],1),_c('div',[_c('div',[_c('span',{staticClass:"font-weight-medium",staticStyle:{"color":"#b71c1c"}},[_vm._v(" "+_vm._s(g.customer_goods_barcode)+" ")]),_vm._v(" - "+_vm._s(_vm.$t("labels.sku"))+": "+_vm._s(g.sku)+" "),_c('span',{staticStyle:{"color":"#b71c1c"}},[_vm._v("* "+_vm._s(g.quantity))])]),_c('div',[_vm._v(" "+_vm._s(g.name)+" - "+_vm._s(g.description)+" - "+_vm._s(g.size)+" ")])])])})],2),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.available))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.isPos ? item.pos_code : item.warehouse_code)+" ")]),_c('td',{staticClass:"text-center"},[(
                      !_vm.selectedComboIds.includes(
                        `${item.id}_${item.id_warehouse}_${item.id_pos}`
                      )
                    )?_c('div',[(item.price !== null)?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.selectItem(item, 'combo')}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.price))+" ")]):_c('span',{staticClass:"font-italic error--text"},[_vm._v(" "+_vm._s(_vm.$t("labels.not_yet_price"))+" ")])],1):_c('div',[_vm._v(" "+_vm._s(item.price || item.price == 0 ? _vm.formatNumber(item.price) : _vm.$t("labels.not_yet_price"))+" ")]),(item.discount_price > 0)?_c('div',{staticClass:"success--text mt-1"},[_c('v-icon',{attrs:{"color":"success","size":"small"}},[_vm._v("mdi-arrow-down")]),_vm._v(" "+_vm._s(_vm.formatNumber(item.discount_price))+" ")],1):_vm._e()])])}),0)]},proxy:true}],null,false,3453118014)}),(_vm.comboTotalPage > 1)?_c('div',{staticClass:"pt-2"},[_c('v-pagination',{staticClass:"mini-pagination",attrs:{"length":_vm.comboTotalPage,"total-visible":6},model:{value:(_vm.comboPage),callback:function ($$v) {_vm.comboPage=$$v},expression:"comboPage"}})],1):_vm._e()],1):_vm._e()]:[_c('div',{staticClass:"result-item font-italic"},[_c('div',[_vm._v(" "+_vm._s(!_vm.value ? _vm.$t("labels.enter_barcode_sku_name_description_size") : "")+" ")])])]],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }