<template>
  <div>
    <div v-if="lead && lead.id">
      <v-row :key="`lead_${lead.id}`">
        <v-col cols="12" md="8">
          <LeadDetailInfo
            :lead="lead"
            action-from="double-check"
            class="mb-5"
          />

          <LeadDetailItem
            :lead="lead"
            action-from="double-check"
            :items="items"
          />
        </v-col>
        <v-col cols="12" md="4">
          <LeadDetailAction
            :lead="lead"
            action-from="double-check"
            class="mb-5"
          />

          <v-card class="card-custom-shadow">
            <v-card-text>
              <LeadDetailNote
                :lead="lead"
                action-from="double-check"
                :key="noteKey"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div
      v-else-if="isLeadGot"
      class="text-center error--text font-italic pt-10"
    >
      {{ $t("labels.lead_not_found") }}
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "LeadDoubleCheck",
  components: {
    LeadDetailItem: () => import("@/components/crm/LeadDetailItem"),
    LeadDetailNote: () => import("@/components/crm/LeadDetailNote"),
    LeadDetailInfo: () => import("@/components/crm/LeadDetailInfo"),
    LeadDetailAction: () => import("@/components/crm/LeadDetailAction"),
  },
  data: () => ({
    isLeadGot: false,
    lead: {},
    items: [],
    id: null,
    noteKey: "",
  }),
  mounted() {
    const id = this.getUrlParameter("id");
    if (!id) {
      this.getCurrentLead();
    } else {
      this.id = id;
      this.getDetail();
    }
    this.listenSocket();
  },
  methods: {
    getCurrentLead() {
      httpClient.post("/crm-lead-double-check").then(({ data }) => {
        this.id = (data && data.id) || null;
        this.getDetail();
      });
    },
    getDetail() {
      if (!this.id) {
        this.isLeadGot = true;
        return false;
      }
      httpClient
        .post("/crm-lead-get-detail", {
          id: this.id,
        })
        .then(({ data }) => {
          this.lead = { ...data.lead };
          this.items = [...data.items];
          this.isLeadGot = true;
        });
    },
    listenSocket() {
      const self = this;
      window.socket.on("crm_lead_process_update", (data) => {
        if (!self.id) {
          self.id = data.id;
          this.getDetail();
        } else if (data.id == self.id) {
          self.noteKey = self.generateRandomString(10);
        }
      });
    },
  },
};
</script>

<style scoped></style>
