<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ item.tracking_id }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <LeadDetailNote :lead="item" :is-show-input="false" />

      <!-- <v-simple-table
        class="table-padding-2"
        fixed-header
        height="calc(100vh - 400px)"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.create_time") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.action_name") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.identity_processed") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" class="text-center">
              <td>{{ formatDateTime(item.created_at) }}</td>
              <td>{{ $t(`labels.lead_action_${item.id_crm_action}`) }}</td>
              <td>{{ item.identity_name }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table> -->
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "LeadLogAction",
  components: {
    LeadDetailNote: () => import("@/components/crm/LeadDetailNote"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
  }),
  created() {},
  mounted() {
    // this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    getList: debounce(function () {
      httpClient
        .post("/crm-lead-get-log-action", { id: this.item.id })
        .then(({ data }) => {
          this.items = [...data];
        });
    }, 500),
  },
};
</script>

<style scoped></style>
