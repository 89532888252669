<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.import_goods_cost") }}
      <v-spacer></v-spacer>
      <a
        class="mr-1 v-btn v-btn--outlined theme--light v-size--small default"
        :href="templateLink"
        target="_blank"
      >
        <v-icon>mdi-download</v-icon>
        {{ $t("labels.sample_file") }}
      </a>
      <v-btn
        color="info"
        class="mr-1"
        small
        outlined
        @click="$refs.inputUploadFile.click()"
      >
        {{ $t("labels.upload") }}
      </v-btn>
      <input
        type="file"
        ref="inputUploadFile"
        accept=".xlsx"
        @change="onInputFileChange"
        class="d-none"
      />

      <v-btn
        color="success"
        class="mr-1"
        small
        outlined
        @click="confirm"
        :disabled="isDisabled"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="error" small outlined @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table class="table-padding-2-no-top">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.receipt_session_tracking") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.barcode") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.supplier") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.goods_cost") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.invoice_no") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.invoice_date") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 70px">
                <v-btn color="success" x-small @click="addRow">
                  {{ $t("labels.add") }}
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in items"
              :key="`cost_${key}`"
              class="text-center"
            >
              <td>
                <v-text-field
                  v-if="item.editing"
                  v-model="item.tracking_id"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <span v-else>{{ item.tracking_id }}</span>
              </td>
              <td>
                <v-text-field
                  v-if="item.editing"
                  v-model="item.customer_goods_barcode"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <span v-else>{{ item.customer_goods_barcode }}</span>
              </td>
              <td>
                <v-text-field
                  v-if="item.editing"
                  v-model="item.supplier_code"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <span v-else>{{ item.supplier_code }}</span>
              </td>
              <td>
                <input-number
                  v-if="item.editing"
                  v-model.number="item.cost"
                  type="number"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                />
                <span v-else>
                  {{ item.cost != "" ? formatNumber(item.cost) : item.cost }}
                </span>
              </td>
              <td>
                <v-text-field
                  v-if="item.editing"
                  v-model="item.invoice_no"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <span v-else>{{ item.invoice_no }}</span>
              </td>
              <td>
                <date-picker-model
                  v-if="item.editing"
                  v-model="item.invoice_date"
                  custom-class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></date-picker-model>
                <span v-else>
                  {{
                    item.invoice_date
                      ? formatDateTime(item.invoice_date, "DD/MM/YYYY")
                      : item.invoice_date
                  }}
                </span>
              </td>
              <td class="text-center">
                <v-btn
                  v-if="item.editing"
                  small
                  color="error"
                  @click="delRow(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { read, utils } from "xlsx";
import moment from "moment";

export default {
  name: "CostUpdate",
  data: () => ({
    isLoading: false,
    items: [],
    name: null,
    idWarehouse: null,
    warehouses: [],
  }),
  computed: {
    isDisabled() {
      return !this.items || this.items.length === 0;
    },
    templateLink() {
      return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_cogs.xlsx`;
    },
  },
  mounted() {},
  methods: {
    async onInputFileChange(e) {
      this.errRows = [];
      this.errTexts = [];
      const files = e.target.files;

      const mappingFields = {
        "Mã phiên nhậpSession ID": "tracking_id",
        Barcode: "customer_goods_barcode",
        "Giá vốn hàng bánCost of Good Sold": "cost",
        "MÃ nhà cung cấpSupplier ID": "supplier_code",
        "Số hóa đơnInvoice Number": "invoice_no",
        "Ngày phát hànhIssue Date(DD/MM/YYYY)": "invoice_date",
      };

      if (files && files[0]) {
        const f = files[0];
        this.file = f;
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let values = utils.sheet_to_json(worksheet);
          console.log(values);
          const items = [...values].map((v) => {
            const item = {
              editing: false,
            };
            Object.keys(v).forEach((i) => {
              const mapkey = i.replace(/\r?\n|\r/g, "");
              // item[mappingFields[mapkey]] = `${v[i]}`.trim();
              if (mappingFields[mapkey] == "invoice_date") {
                item[mappingFields[mapkey]] = moment(
                  this.excelDateToJSDate(v[i])
                ).format("YYYY-MM-DD");
              } else {
                item[mappingFields[mapkey]] = `${v[i]}`.trim();
              }
            });
            return item;
          });
          this.beforeSetItems(items);
        };
        await reader.readAsArrayBuffer(f);
      }
    },
    beforeSetItems(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error(this.$t("messages.file_is_empty"));
        return false;
      }
      if (items.length > 1000) {
        this.$vToastify.error(this.$t("messages.update_max_1000_rows"));
        return false;
      }
      this.items = [...items];
    },
    cancel() {
      this.$emit("cancel");
    },
    async confirm() {
      if (this.isDisabled) {
        return false;
      }

      const errorIndexs = [];
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if (
          !item.tracking_id ||
          !item.customer_goods_barcode ||
          item.cost == null ||
          item.cost == ""
        ) {
          errorIndexs.push(i + 1);
        }
        const checkDuplicates = this.checkDuplicate(item, i);
        if (checkDuplicates && checkDuplicates.length > 0) {
          errorIndexs.push(i + 1);
        }
      }

      if (errorIndexs.length > 0) {
        this.$vToastify.warning(
          this.$t("messages.error_rows", { rows: errorIndexs.join("; ") })
        );
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-update-cost-by-receipt", {
          items: this.items,
        });
        this.isLoading = false;
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    checkDuplicate(item, index) {
      return [...this.items].filter(
        (s, i) =>
          i != index &&
          s.customer_goods_barcode == item.customer_goods_barcode &&
          s.tracking_id == item.tracking_id
      );
    },
    addRow() {
      this.items.push({
        tracking_id: null,
        customer_goods_barcode: null,
        cost: "",
        editing: true,
      });
    },
    delRow(index) {
      this.items = [...this.items].filter((item, i) => i !== index);
    },
    async getList() {
      httpClient
        .post("/supplier-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows].map((r) => {
            const type = this.typeOptions.find((t) => t.value == r.type);
            return { ...r, type_txt: type.text, editing: false };
          });
        });
    },
  },
};
</script>

<style scoped></style>
