<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      api-url="/invoice-changed"
      table-height="calc(100vh - 196px)"
      @showDialogProcess="showDialogProcess"
    ></custom-table>
  </div>
</template>

<script>
import {
  CUSTOM_VIEW_BILL_WAIT_PROCESS,
  ORDER_SOURCES,
  INVOICE_TAX_STATUS,
  INVOICE_PORTAL_TAX_STATUS,
} from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "Changed",
  components: {},
  data: () => ({
    columns: [],
    customView: CUSTOM_VIEW_BILL_WAIT_PROCESS,
    sourceOptions: [...ORDER_SOURCES],
    processDialog: false,
    updatingItem: {},
    taxStatusOptions: [...INVOICE_TAX_STATUS],
  }),
  created() {
    this.getActiveContract();
  },
  methods: {
    async getActiveContract() {
      const { data } = await httpClient.post("/invoice-get-active-contract");
      if (data.invoice_type == 2) {
        this.taxStatusOptions = [...INVOICE_PORTAL_TAX_STATUS];
      } else {
        this.taxStatusOptions = [...INVOICE_TAX_STATUS];
      }
      this.setTableConfig();
    },
    setTableConfig() {
      const columns = [
        {
          type: "input-filter",
          label: this.$t("labels.tracking"),
          placeholder: this.$t("labels.tracking"),
          name: "tracking_id",
          hasSort: false,
          sortName: "tracking_id",
          key: "tracking_id",
          required: true,
        },
        {
          type: "date-range-filter",
          label: this.$t("labels.date"),
          placeholder: this.$t("labels.date"),
          name: "created_at",
          hasSort: false,
          sortName: "created_at",
          key: "created_at",
          required: true,
        },
        {
          type: "select-filter",
          label: this.$t("labels.order_source"),
          placeholder: this.$t("labels.order_source"),
          name: "source",
          hasSort: false,
          sortName: "source",
          key: "source",
          options: this.sourceOptions,
          required: true,
        },
        {
          type: "select-e-market-shop",
          label: this.$t("labels.shop"),
          placeholder: this.$t("labels.shop"),
          class: "c-input-xs input-filter",
          name: "id_customer_e_marketplace_shop",
          hasSort: false,
          sortName: "customer_e_marketplace_shop_name",
          key: "customer_e_marketplace_shop_name",
          required: true,
          params: {
            "is-check-e-market": false,
            "is-show-publish-invoice-only": true,
          },
        },
        {
          type: "input-filter",
          label: this.$t("labels.invoice_order_changed"),
          placeholder: this.$t("labels.invoice_order_changed"),
          name: "note",
          hasSort: false,
          sortName: "note",
          key: "note",
          required: true,
        },
        {
          type: "input-filter",
          label: this.$t("labels.invoice_is_adjusted"),
          placeholder: this.$t("labels.invoice_is_adjusted"),
          name: "invoice_no_origin",
          hasSort: false,
          sortName: "invoice_no_origin",
          key: "invoice_no_origin",
          required: true,
        },
        {
          type: "input-filter",
          label: this.$t("labels.adjustment_invoice"),
          placeholder: this.$t("labels.adjustment_invoice"),
          name: "invoice_no",
          hasSort: false,
          sortName: "invoice_no",
          key: "invoice_no",
          required: true,
        },
        {
          type: "select-filter",
          label: this.$t("labels.status"),
          placeholder: this.$t("labels.status"),
          name: "tax_status",
          hasSort: false,
          sortName: "tax_status",
          key: "tax_status",
          options: this.taxStatusOptions,
          required: true,
        },
      ];
      this.columns = [...columns];
    },
    showDialogProcess(item) {
      this.updatingItem = { ...item };
      this.processDialog = true;
    },
    hideDialogProcess() {
      this.updatingItem = {};
      this.processDialog = false;
    },
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
  },
};
</script>
