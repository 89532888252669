<template>
  <div class="position-relative" v-click-outside="hideDropdown">
    <v-text-field
      v-model="value"
      :label="$t('labels.barcode_sku_name_description_size')"
      :disabled="disabled"
      outlined
      dense
      hide-details
      @focus="showDropdown"
      @keyup="getSuggestion"
      class="c-input-small"
      :placeholder="$t('labels.barcode_sku_name_description_size')"
    ></v-text-field>
    <div class="position-absolute result-list" v-if="dropdown">
      <template v-if="suggestions.length > 0 || comboSuggestions.length > 0">
        <!-- Goods -->
        <div v-if="suggestions.length > 0" class="mb-10">
          <v-simple-table class="table-padding-2-no-top">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th class="text-center error--text" style="width: 50%">
                    {{ $t("labels.product") }}
                  </th>
                  <th class="text-center">{{ $t("labels.size") }}</th>
                  <th class="text-center">{{ $t("labels.available") }}</th>
                  <th class="text-center">
                    {{ isPos ? $t("labels.pos") : $t("labels.warehouse_1") }}
                  </th>
                  <th class="text-center">{{ $t("labels.price") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="result-item text-center"
                  :class="{
                    'selected-item': selectedGoodsIds.includes(suggestion.id),
                  }"
                  v-for="suggestion in suggestions"
                  :key="`g_${suggestion.id}_${suggestion.id_warehouse}_${suggestion.id_pos}`"
                >
                  <td class="text-left">
                    <div class="d-flex align-center">
                      <div style="width: 50px">
                        <ImageViewer
                          :url="
                            suggestion.url_images ||
                            require('@/assets/common/no-image.jpg')
                          "
                          width="40px"
                          height="40px"
                        />
                      </div>
                      <div>
                        <div>
                          <span
                            class="font-weight-medium"
                            style="color: #b71c1c"
                          >
                            {{ suggestion.customer_goods_barcode }}
                          </span>
                          - {{ $t("labels.sku") }}: {{ suggestion.sku }}
                        </div>
                        <div>
                          {{ suggestion.name }} - {{ suggestion.description }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{{ suggestion.size }}</td>
                  <td>{{ suggestion.available }}</td>
                  <td>
                    {{
                      isPos ? suggestion.pos_code : suggestion.warehouse_code
                    }}
                  </td>
                  <td>
                    <template
                      v-if="
                        !selectedGoodsIds.includes(
                          `${suggestion.id}_${suggestion.id_warehouse}_${suggestion.id_pos}`
                        )
                      "
                    >
                      <v-btn
                        x-small
                        color="primary"
                        v-if="suggestion.price !== null"
                        @click="selectItem(suggestion, 'price', 'goods')"
                      >
                        {{ formatNumber(suggestion.price) }}
                      </v-btn>
                      <span class="font-italic error--text" v-else>
                        {{ $t("labels.not_yet_price") }}
                      </span>
                    </template>
                    <template v-else>
                      {{
                        suggestion.price || suggestion.price == 0
                          ? formatNumber(suggestion.price)
                          : $t("labels.not_yet_price")
                      }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="pt-2" v-if="totalPage > 1">
            <v-pagination
              class="mini-pagination"
              v-model="page"
              :length="totalPage"
              :total-visible="6"
            ></v-pagination>
          </div>
        </div>

        <!-- Combo -->
        <div v-if="comboSuggestions.length > 0">
          <v-simple-table class="table-padding-2-no-top">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th class="text-center error--text" style="width: 50%">
                    {{ $t("labels.combo") }}
                  </th>
                  <th class="text-center">{{ $t("labels.available") }}</th>
                  <th class="text-center">
                    {{ isPos ? $t("labels.pos") : $t("labels.warehouse_1") }}
                  </th>
                  <th class="text-center">{{ $t("labels.price") }}</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  class="result-item"
                  :class="{
                    'selected-item': selectedComboIds.includes(item.id),
                  }"
                  v-for="item in comboSuggestions"
                  :key="`cb_${item.id}_${item.id_warehouse}_${item.id_pos}`"
                >
                  <td class="text-left">
                    <div class="pl-1">
                      <span class="font-weight-medium" style="color: #b71c1c">
                        {{ item.barcode }}
                      </span>
                      -
                      <span>{{ item.name }}</span>
                    </div>

                    <div
                      class="d-flex align-center"
                      v-for="(g, k) in item.goods"
                      :key="`cg_${k}_${g.id}`"
                    >
                      <div style="width: 50px">
                        <ImageViewer
                          :url="
                            g.url_images ||
                            require('@/assets/common/no-image.jpg')
                          "
                          width="40px"
                          height="40px"
                        />
                      </div>
                      <div>
                        <div>
                          <span
                            class="font-weight-medium"
                            style="color: #b71c1c"
                          >
                            {{ g.customer_goods_barcode }}
                          </span>
                          - {{ $t("labels.sku") }}: {{ g.sku }}
                          <span style="color: #b71c1c">* {{ g.quantity }}</span>
                        </div>
                        <div>
                          {{ g.name }} - {{ g.description }} - {{ g.size }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    {{ formatNumber(item.available) }}
                  </td>
                  <td class="text-center">
                    {{ isPos ? item.pos_code : item.warehouse_code }}
                  </td>
                  <td class="text-center">
                    <div
                      v-if="
                        !selectedComboIds.includes(
                          `${item.id}_${item.id_warehouse}_${item.id_pos}`
                        )
                      "
                    >
                      <v-btn
                        x-small
                        color="primary"
                        v-if="item.price !== null"
                        @click="selectItem(item, 'combo')"
                      >
                        {{ formatNumber(item.price) }}
                      </v-btn>
                      <span class="font-italic error--text" v-else>
                        {{ $t("labels.not_yet_price") }}
                      </span>
                    </div>
                    <div v-else>
                      {{
                        item.price || item.price == 0
                          ? formatNumber(item.price)
                          : $t("labels.not_yet_price")
                      }}
                    </div>
                    <div
                      v-if="item.discount_price > 0"
                      class="success--text mt-1"
                    >
                      <v-icon color="success" size="small"
                        >mdi-arrow-down</v-icon
                      >
                      {{ formatNumber(item.discount_price) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="pt-2" v-if="comboTotalPage > 1">
            <v-pagination
              class="mini-pagination"
              v-model="comboPage"
              :length="comboTotalPage"
              :total-visible="6"
            ></v-pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="result-item font-italic">
          <div>
            {{
              !value ? $t("labels.enter_barcode_sku_name_description_size") : ""
            }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "LeadSelectSuggestItem",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    fromId: {
      type: [String, Number],
      default: () => null,
    },
    fromType: {
      type: [String, Number],
      default: () => null,
    },
    label: {
      type: String,
      default: () => null,
    },
    suggestionApiUrl: {
      type: String,
      default: () => null,
    },
    defaultValue: {
      type: String,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    selectItems: {
      type: Array,
      default: () => [],
    },
    isPos: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    value: null,
    dropdown: false,
    newItem: {},
    suggestions: [],
    page: 1,
    totalPage: 1,
    comboSuggestions: [],
    comboPage: 1,
    comboTotalPage: 1,
  }),
  computed: {
    selectedGoodsIds() {
      return [...this.selectItems]
        .filter((s) => !!s.id_goods)
        .map((s) => `${s.id_goods}_${s.id_warehouse}_${s.id_pos}`);
    },
    selectedComboIds() {
      return [...this.selectItems]
        .filter((s) => !!s.id_combo)
        .map((s) => `${s.id_combo}_${s.id_warehouse}_${s.id_pos}`);
    },
  },
  watch: {
    page() {
      this.getSuggestion();
    },
    value() {
      this.page = 1;
    },
  },
  mounted() {},
  methods: {
    onChange() {},
    getSuggestion: debounce(function () {
      const url = this.isPos ? "/pos-goods-list" : "/goods-list";
      httpClient
        .post(url, { keywords: this.value, page: this.page, limit: 5 })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.suggestions = [...data.rows];
        });
      if (!this.isPos) {
        httpClient
          .post("/goods-list-combo", {
            keywords: this.value,
            page: this.comboPage,
            limit: 5,
          })
          .then(({ data }) => {
            this.comboTotalPage = data.totalPage;
            this.comboSuggestions = [...data.rows].map((item) => {
              let goods_price = 0;
              for (let g = 0; g < item.goods.length; g++) {
                const goods = item.goods[g];
                goods_price += +goods.price;
                goods.ratio_price = item.price / goods.price;
                item.goods[g] = { ...goods };
              }

              let discount_price = 0;

              if (item.price !== null) {
                discount_price = goods_price - item.price;
              }
              return {
                ...item,
                discount_price,
              };
            });
          });
      }
    }, 500),
    selectItem(item, item_type) {
      this.$emit("onSelect", { item, item_type });
    },
    showDropdown() {
      this.dropdown = true;
    },
    hideDropdown() {
      this.dropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.result-list {
  width: 100%;
  background: #fff;
  z-index: 1;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  // max-height: 300px;
  // overflow-y: auto;
  border-radius: 0 0 5px 5px;

  .result-item {
    padding: 8px;
    border-bottom: 1px solid #f3f3f3;
    font-size: 12px;

    &:hover {
      background: #efefef;
    }

    &.selected-item {
      background-color: #eff6fd;
      color: #000000;
      font-weight: 600;
    }
  }
}
</style>
